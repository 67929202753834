import React, {
  useState,
  useEffect,
  createContext,
  useContext,
  useRef,
} from "react";
import * as authHelper from "utils/auth-helper";
import Api from "utils/api";
import Loader from "component/loader";
import { useAgency } from "./agency";
import { useSearchParams } from "react-router-dom";

const initAuthContextPropsState = {
  auth: authHelper.getAuth(),
  saveAuth: () => {},
  currentUser: undefined,
  setCurrentUser: () => {},
  logout: () => {},
  tour: {},
  setTour: () => {},
  updateTour: async () => {},
};

const AuthContext = createContext(initAuthContextPropsState);

const useAuth = () => {
  return useContext(AuthContext);
};

const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState(authHelper.getAuth());
  const [currentUser, setCurrentUser] = useState();
  const [tour, setTour] = useState({});

  const saveAuth = (auth) => {
    setAuth(auth);
    if (auth) {
      authHelper.setAuth(auth);
    } else {
      authHelper.removeAuth();
    }
  };

  const logout = () => {
    saveAuth(undefined);
    setCurrentUser(undefined);
  };

  const updateTour = async (page) => {
    // will get back here
    const tourUpdates = { ...tour, ...page };
    const { response } = await Api("/user", "put", {
      payload: { tour: tourUpdates },
    });
    if (response) {
      setTour(tourUpdates);
      setCurrentUser({ ...currentUser, tour: tourUpdates });
    }
  };

  return (
    <AuthContext.Provider
      value={{
        auth,
        saveAuth,
        currentUser,
        setCurrentUser,
        logout,
        tour,
        setTour,
        updateTour,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

const AuthInit = ({ children }) => {
  const { getAgency, getPremiumApps, getLocations } = useAgency();
  const { auth, logout, setCurrentUser, setTour } = useAuth();
  const didRequest = useRef(false);
  const [showSplashScreen, setShowSplashScreen] = useState(true);
  console.log(getAgency, "get agency");
  console.log(getLocations, "get location");
  console.log(auth, "auth");
  console.log(setCurrentUser, "setCurrentUser");
  useEffect(() => {
    const requestUser = async () => {
      try {
        if (didRequest.current != auth) {
          setShowSplashScreen(true);
          const { response } = await Api("/auth/verify_token", "get");
          if (response) {
            setCurrentUser(response.data);
            setTour(response.data?.tour || {});
            await getAgency();
            await getPremiumApps();
            await getLocations();
          }
        }
      } catch (error) {
        console.error(error);
        if (!didRequest.current) {
          logout();
        }
      } finally {
        setShowSplashScreen(false);
      }

      didRequest.current = auth;
    };

    if (auth && auth.token) {
      requestUser(auth.token);
    } else {
      logout();
      setShowSplashScreen(false);
    }
  }, [auth]);

  return showSplashScreen ? (
    <div className="w-full h-[100vh] flex flex-col items-center justify-center">
      <Loader />
    </div>
  ) : (
    <>{children}</>
  );
};

export { AuthProvider, AuthInit, useAuth };
